import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter, { RouteConfig } from 'vue-router';
import Error404 from '../views/error-404.vue';
import Home from '../views/home.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue'),
    meta: { transition: 'slide-left' },
  },
  {
    path: '/shows',
    name: 'shows',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shows.vue'),
  },
  {
    path: '/artist/:id',
    name: 'contact-artist',
    component: () => import(/* webpackChunkName: "contact" */ '../views/artist.vue'),
  },
  {
    path: '/artist',
    redirect: '/artist/0',
  },
  {
    path: '/tech',
    name: 'contact-tech',
    component: () => import(/* webpackChunkName: "contact" */ '../views/tech.vue'),
  },
  {
    path: '/other',
    name: 'contact-other',
    component: () => import(/* webpackChunkName: "contact" */ '../views/other.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "contact" */ '../views/privacypolicy.vue'),
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: () => import(/* webpackChunkName: "contact" */ '../views/cookiepolicy.vue'),
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import(/* webpackChunkName: "contact" */ '../views/partners.vue'),
  },
  {
    path: '*',
    name: 'a404page',
    component: Error404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
