<template>
  <div class="su-navbar">
    <b-navbar toggleable="md" class="flex-md-column navbar-dark">
      <b-navbar-brand href="/">
        <img src="/assets/img/logo.svg" class="d-inline-block align-top" alt="Show Up Logo" />
      </b-navbar-brand>

      <!-- Collapse button -->
      <!--<button type="button" aria-label="Toggle navigation" class="navbar-toggler second-button collapsed" aria-expanded="false" aria-controls="nav-collapse" style="overflow-anchor: none;">
        <span class="navbar-toggler-icon"></span>
      </button>
      <button class="navbar-toggler second-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent23" aria-controls="navbarSupportedContent23" aria-expanded="false" aria-label="Toggle navigation">
        <div class="animated-icon2"><span></span><span></span><span></span><span></span></div>
      </button>-->

      <b-navbar-toggle ref="navToggle" target="nav-collapse" class="second-button" @click="toggleButton">
        <div class="animated-icon2" :class="{open : isMenuOpen}"><span></span><span></span><span></span><span></span></div>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="flex-column">
          <b-nav-item :class="{ active: aboutActive}" href="#about-us">About us</b-nav-item>
          <b-nav-item :class="{ active: knowActive}" href="#know-how">Our know-how</b-nav-item>
          <b-nav-item :class="{ active: servicesActive}" href="#services">Our services</b-nav-item>
          <b-nav-item :class="{ active: showsActive}" href="#shows">Our shows</b-nav-item>
          <b-nav-item :class="{ active: contactActive}" href="#contact-us">Contact us</b-nav-item>
          <b-nav-item :class="{ active: workActive}" href="#work-with-us">Work with us</b-nav-item>
          <b-nav-item href="/partners">Our partners</b-nav-item>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script lang="js">
import Vue from 'vue';

export default Vue.extend({
  name: 'MainNavBar',
  props: {
  },
  data() {
    return {
      aboutActive : false,
      knowActive : false,
      servicesActive : false,
      showsActive : false,
      contactActive: false,
      workActive: false,
      isMenuOpen: false
    }

  },
  methods:{
    toggleButton: function(){
      this.$data.isMenuOpen = !this.$data.isMenuOpen;
    },
    setFocus: function(itemFocus){
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this;
      switch (itemFocus) {
        case 1:
          self.$data.aboutActive = true;
          break;
        case 2:
          self.$data.knowActive = true;
          break;
        case 3:
          self.$data.servicesActive = true;
          break;
        case 4:
          self.$data.showsActive = true;
          break;
        case 5:
          self.$data.contactActive = true;
          break;
        case 6:
          self.$data.workActive = true;
          break;

        default:
          break;
      }
    },
    setNotFocus: function(itemFocus){
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let self = this;
      switch (itemFocus) {
        case 1:
          self.$data.aboutActive = false;
          break;
        case 2:
          self.$data.knowActive = false;
          break;
        case 3:
          self.$data.servicesActive = false;
          break;
        case 4:
          self.$data.showsActive = false;
          break;
        case 5:
          self.$data.contactActive = false;
          break;
        case 6:
          self.$data.workActive = false;
          break;
        default:
          break;
      }
    }
  }
});
</script>
