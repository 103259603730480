
import Vue from 'vue';

export default Vue.extend({
  name: 'Alert',
  props: {},
  data() {
    return{
      dismissSecs: 4,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      message: ""
    }
  },
  methods:{
    countDownChanged: function(dismissCD) {
      this.$data.dismissCountDown = dismissCD;
    },
    showAlert: function(message) {
      this.$data.message = message;
      this.$data.dismissCountDown = this.$data.dismissSecs
    }
  }
});
