<template>
  <section class="a404page">

    <secondary-nav></secondary-nav>

    <div class="su-body">

      <div class="a404page-section container">
        <div class="row">
          <div class="col-2 void-column"></div>

          <div class="col-12 col-md-10">
            <div class="row">
              <div class="col-12">
                <h1>
                  404 - NOT FOUND
                </h1>
              </div>

              <div class="col-12">
                <h3>
                  We can't find this page on our website.
                </h3>
              </div>

            </div>
          </div>
        </div>

        <footer-row></footer-row>
      </div>

    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';
import SecondaryNavBar from "@/components/secondary-nav";
import Footer from '@/components/footer.vue'

export default Vue.extend({
  name: 'a404page',
  metaInfo: {
    title: '404page',
  },
  components: {
    'secondary-nav' : SecondaryNavBar,
    'footer-row' : Footer
  },
});
</script>
