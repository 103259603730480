<template>
      <div
        class="loading"
        :class="{activeLoading: isLoading}">

          <div class="px-5 py-3 bg-gray-800 rounded d-flex flex-column">
            <img src="/assets/img/logo.svg" alt="Show Up Logo" />
            <h3 class="text-3xl text-white">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </h3>
            <p>This could take a while, based on your connection.</p>
          </div>
      </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    changeStatus(isLoading) {
      this.isLoading = isLoading;
    },
    changeIsLoadingOptions (newOptions) {
      Object.keys(this.options).forEach((key) => {
        if (newOptions[key]) {
          this.options[key] = newOptions[key]
        }
      })
    }
  },
};
</script>

<style scoped>
.loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 99999 !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-height: 0;
  background-color: black;
  transition: max-height 2s ease-in-out;

}
.loading i {
  position: absolute;
  opacity: 1;
}


.loading.activeLoading{
  max-height: 100%;
  transition: none;


}

.loading h3{
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.loading p{
  font-size: 10px;
}
</style>
